<template>
  <div>
    <p>
      This form, although pretty is not functional.
      If you want to contact me until I get it fixed
    </p>
    <div @click="hideEmail = false" v-if="hideEmail">Click Here</div>
    <div v-else>
      <a href="mailto:microwavehead@gmail.com">Email</a>
      or to
      <a @click.prevent="copyEmail()">Copy to Clipboard</a>
    </div>
    <input type="hidden" id="email-source" :value="emailValue">
    <VForm v-model="valid">
      <VTextField v-model="name" label="Name" :rules="[...requiredRule]" :disabled="sending" />
      <VTextField
        v-model="email"
        label="Email"
        :rules="[...requiredRule, ...emailRules]"
        :disabled="sending"
      />
      <VSelect
        v-model="requestType"
        label="Request Type"
        :items="queryItems"
        :rules="[...requiredRule]"
        :disabled="sending"
      />
      <VTextarea
        v-model="message"
        label="Message"
        :autoGrow="true"
        rows="1"
        counter="1000"
        :rules="[...requiredRule]"
        :disabled="sending"
      />
      <div class="button-holder">
        <v-btn :disabled="!valid" color="success" @click="sendMail" v-if="!sending">Send</v-btn>
        <LoadingIcon v-else />
      </div>
    </VForm>
    <v-snackbar v-model="snackbar" :color="snackbarColor">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import LoadingIcon from './LoadingIcon.vue';

export default {
  components: {
    LoadingIcon,
  },
  data() {
    return {
      emailValue: 'microwavehead@gmail.com',
      snackbar: false,
      snackbarText: '',
      snackbarColor: '',
      hideEmail: true,
      valid: false,
      name: '',
      email: '',
      requestType: '',
      message: '',
      queryItems: ['Commission', 'General Query'],
      requiredRule: [
        (value) => !!value || 'Required.',
      ],
      emailRules: [
        (value) => /.+@.+/.test(value) || 'E-mail must be valid',
      ],
      sending: false,
    };
  },
  methods: {
    sendMail() {
      this.sending = true;
      const self = this;

      this.$api.post('communicate',
        {
          name: self.name,
          email: self.email,
          requestType: self.requestType,
          message: self.message,
        })
        .then(() => {
          self.$router.push({ name: 'contact-thanks' });
        });
    },
    copyEmail() {
      const testingCodeToCopy = document.querySelector('#email-source');
      testingCodeToCopy.setAttribute('type', 'text');
      testingCodeToCopy.select();

      try {
        document.execCommand('copy');
      } catch {
        this.snackbar = true;
        this.snackbarText = 'Email failed to copied';
        this.snackbarColor = 'error';
      } finally {
        testingCodeToCopy.setAttribute('type', 'hidden');
        window.getSelection().removeAllRanges();
        this.snackbar = true;
        this.snackbarText = 'Email copied';
        this.snackbarColor = 'success';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.button-holder {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 15px;
}

.v-form {
  margin: 15px 10px;
}
</style>
